import SelectOptionModel from '@/Modules/Dataset/SelectOptionModel.js'

export default {
  created() {
    this.$nextTick(() => this.errors.clear())

    this.documentFolders =
      this.meta.documentFolders &&
      this.meta.documentFolders.filter((item) => {
        return item.value !== this.meta.document.id
      })

    this.documentFolders.unshift(new SelectOptionModel({
      label: './',
      value: null
    }))
  },
}
